import React from 'react';  
import { connect } from "react-redux"
import  styles from "./email-friend.module.css";
import {EmailFriendPopupSchema} from "../../../../schemas/prompts/careers/investor/email-friend"
import {hideInvestorEmailFriendPopup} from "../../../../actions/investor/prompts/careers/email-friend"
import {constructInvestorEmailFriendFormEmailHtml, submitEmailFriendFormInvestor} from "../../../../controllers/investor/mailer"
import Editor from "./../../../Editor"
import CircularProgress from "@material-ui/core/CircularProgress"
import {isEmailValid} from "../../../../util/"


class Popup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            senderEmail: "",
            recipientName: "",
            recipientEmail: "",
            loading: false,
            error: null,
            wasValidated: false
        }
    }

    closePopup = () => {
        let popupConfig = new EmailFriendPopupSchema()
        this.props.hideInvestorEmailFriendPopup(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    onTextChange = ({target: {name, value}}) => {
        this.setState({[name]: value})
    }

    clearForm = () => {
        let clearedFormData = Object.keys(this.state).reduce((acc, item) => {
            if(item === "loading" || item === "wasValidated"){
                acc[item] = false;
            }else if(item === "error"){
                acc[item] = null;
            }else{
                acc[item] = "";
            }
            return acc
        }, {});
        this.setState({...this.state, ...clearedFormData})
    }

    isEmpty = (items) => {
        if(items.includes("")){
            return true;
        }
        return false;
    }

    submitForm = async(okCallback) => {
        const {senderEmail, recipientName, recipientEmail, loading} = this.state;

        if(loading) return
        
        this.setState({wasValidated: true, loading: true, error: null})

        if(this.isEmpty([senderEmail, recipientName, recipientEmail])){
            this.setState({error: {response: {data: "Please fill in all required fields."}}, loading: false})
            return;
        }

        if(!isEmailValid(senderEmail) || !isEmailValid(recipientEmail)){
            this.setState({error: {response: {data: "Some email is invalid."}}, loading: false})
            return;
        }

        let data = {
            subject: "Investor and Media Relations Job Vacancy - Email A Friend",
            recipient: {recipientName, recipientEmail},
            html: constructInvestorEmailFriendFormEmailHtml({senderEmail, recipientName, recipientEmail, ...this.props.popupConfig.queryData, jobLink: `${window.location.href}` })
        }

        try {
            await submitEmailFriendFormInvestor(data)
            this.clearForm()

            if(typeof okCallback === "function"){
                await okCallback()
            }
            this.closePopup()
        }catch(e){
            this.setState({error: e})
        }
        this.setState({loading: false, wasValidated: false})

    }

    render() {  
        const { show, title, description, queryData, okCallback, closeCallback, okLabel, closeLabel} = this.props.popupConfig;
        const {senderEmail, recipientName, recipientEmail, error, wasValidated} = this.state;
        
        if(!show) return <React.Fragment />

        return (   
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
            <p>
                <strong>{title}</strong>
            </p> 
            <p>
                {description}
            </p>
            <div className="row">
                <div className="col-12">
                   <Editor type={"text"} name={"senderEmail"} label={"Sender's Email Address"} value={senderEmail} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && !isEmailValid(senderEmail)} />
                </div>
                <div className="col-12">
                    <Editor type={"text"} name={"recipientName"} label={"Recipient's Name"} value={recipientName} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && recipientName.length < 1} />
                </div>
                <div className="col-12">
                   <Editor type={"text"} name={"recipientEmail"} label={"Recipient's Email Address"} value={recipientEmail} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && !isEmailValid(recipientEmail)} />
                </div>
            </div>
            {error && (
                <div className="col-12">
                    <small className="text-danger">
                    {`${error.response ? error.response.data : "Something went wrong, please try again"}`}
                    </small>
                </div>
            )}
           <div className="text-center mt-3">
           <button className={"custom_btn dark bordered_btn"} onClick={async () => await this.submitForm(okCallback)}>
                {this.state.loading ? <CircularProgress size={20}/> : okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button> 
            </div> 
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = () => {
    return {
        hideInvestorEmailFriendPopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)