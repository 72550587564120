import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import styles from "../../components/header.module.css"
import {
  Accordion,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import classnames from "classnames";
import { window } from 'browser-monads';
import disableScroll from 'disable-scroll';
import { Icon } from 'react-icons-kit'
import {search} from 'react-icons-kit/fa/search'
import InvestorDisclaimerPopup from "../prompts/disclaimers/investor/disclaimer-popup"
import InvestorApplyOnlinePopup from "../prompts/careers/investor/apply-online"
import InvestorEmailFriendPopup from "../prompts/careers/investor/email-friend"
import FranceCaptchaPopup from "../prompts/shared/france-captcha-popup"
import {showFranceCaptchaPopup} from "../../actions/shared/france-captcha-popup"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"
import InvestorAuthFormPopup from "../prompts/forms/auth-form"
import {investorSearchTermOnchange} from "../../actions/investor/util/search_onchange"

import {linkedin} from 'react-icons-kit/fa/linkedin'
import {twitter} from 'react-icons-kit/fa/twitter'
import {document} from "browser-monads"
import { isNullUndefined, transformMarkdownToReact } from "../../util"
import { config, getUsaOnlyInvestorDisclaimerPopupConfigsInvestors, getDefaultInvestorDisclaimerPopupConfigs } from "../../util/configs"

const investorQuery = graphql`
query HeaderInvestorQuery {
  allContentfulMenuItem {
    edges {
      node {
        slug
        position
        title
        bodyText {
          childrenMarkdownRemark {
           html
          }
        }
        bodyText {
            childMarkdownRemark {
             htmlAst
            }
          }
        spaceId
        default_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        cards_only_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        tabs_for_files_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        country_information_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        reports_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        news_list_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        iframe_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        current_vacancies_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        advisers_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        share_information_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        accordion_template {
          slug
          title
          position
          parentMenu {
            slug
          }
        }
        usa_investors_only_menu_template {
					slug
          title
          position
          parentMenu {
						slug
          }
        }
        accordion_for_files_template {
          id
          title
          slug
          parentMenu {
            slug
          }
        }
      }
    }
  }
}
`

const Header = (props) => {
  const globalState = useSelector(state => state);
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true)
  const [searchIsActive, setSearchIsActive] = useState(false)
  const [menuItemIsActive, setmenuItemIsActive] = useState(null)
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 100){
      setPrevScrollpos(currentScrollPos);
      setVisible((prevScrollpos === currentScrollPos))
    }
   else{
    setPrevScrollpos(currentScrollPos);
    setVisible((prevScrollpos === currentScrollPos))
   }
 

  };
  const showExternalLinkDisclaimer = (externalUrl) => {
    let okCallback = () => window.open(externalUrl, "_blank")
    let closeCallback = () => { }
    let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
    dispatch(showInvestorDisclaimerPopup(actionConfig));
  }
  const submitSearch = term => {
    let searchUrl = `/Investor/search?q=${term}`
    dispatch(investorSearchTermOnchange(""))
    navigate(searchUrl);
  }

  const toggleClass = () => setIsActive(!isActive)

  const sortMenuItemByPosition = (a, b) => {
    if(parseInt(a.node.position) > parseInt(b.node.position)){
      return 1;
    }
    if(parseInt(a.node.position) < parseInt(b.node.position)){
      return -1
    }
    return 0;
  }

  const sortSubMenus = (a, b) => {
    if(parseInt(a.position) > parseInt(b.position)){
      return 1;
    }
    if(parseInt(a.position) < parseInt(b.position)){
      return -1
    }
    return 0;
  }


  const scrollPreventionOnActiveNav = () => {
    if(isActive){
      document.body.style.overflow = 'hidden';
    } else{
      document.body.style.overflow = 'auto';
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  const toggleFranceCaptchaPopup = (path) => {
    if(window.location.pathname === path) return;
    
    let actionConfig = { 
      show: true,
      path: path,
    }
    dispatch(showFranceCaptchaPopup(actionConfig))
  }

  const toggleUsaOnlyInvestorPopup = path => {
    if(window.location.pathname === path) return;

    let okCallback = () => navigate(path);
    let closeCallback = () => {}

    let popupConfig = getUsaOnlyInvestorDisclaimerPopupConfigsInvestors(okCallback, closeCallback)

    dispatch(showInvestorDisclaimerPopup(popupConfig))
  }

  const compileSortedSubMenus = (parentMenuSlug, allSubMenuTemplates, isMobile) => {
    
    let pages = [];

    for(let [templateName, submenuArray] of Object.entries(allSubMenuTemplates)){


submenuArray = submenuArray?.filter(item => item?.slug !== "dummy")

      if(!isNullUndefined(submenuArray)){
        
        for(let submenu of submenuArray){
          
          if(submenu.parentMenu.slug === parentMenuSlug && submenu.slug !== "dummy"){
            let path = ""
            let callback = null;
            switch(templateName){
              case "currentVacanciesTemplateSubMenus":
                path = `/Investor/Careers/${submenu.slug}`;
                break;
              case "tabsForFilesTemplateSubMenus":
                path = `/Investor/${submenu.slug}`;
                if(submenu.slug === "france" && submenu.parentMenu.slug === "transparency"){
                  callback = () => toggleFranceCaptchaPopup(path);
                }
                break;
              case "usaInvestorsOnlyInformationTemplate":
                path = `/Investor/${submenu.slug}`;
                if(submenu.slug === "usa-only-investor-information"){
                  callback = () => toggleUsaOnlyInvestorPopup(path);
                }
                break;
              default:
                path = `/Investor/${submenu.slug}`;
                break;
            }
            pages.push({...submenu, pagePath: path, callback: callback})
          }
        }

      }

    }

    if(isMobile){
      return pages.sort(sortSubMenus).map(item => {
        const preventAutoNavigation = (e) => {
          if(!item.callback) return;
          e.preventDefault();
          item.callback();
        }
        return <Link key={item.slug} to={item.pagePath} onClick={preventAutoNavigation}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
      })
    }

    return pages.sort(sortSubMenus).map(item => {
      const preventAutoNavigation = (e) => {
        if(!item.callback) return;
        e.preventDefault();
        item.callback();
      }
      return <Link key={item.slug} to={item.pagePath} onClick={preventAutoNavigation}><li className={`${styles.top_nav_btns}`}>{item.title}</li></Link>
    })
  }


  const query = useStaticQuery(investorQuery);

  const menuItemEdges = query.allContentfulMenuItem.edges.filter(edge => edge.node.spaceId === process.env.GATSBY_INVESTOR_SPACE_ID);
  const setActiveMenuItem = id => {
    setmenuItemIsActive(id)
  }
  return ( 
    <div>
    <header className={`${styles.header} ${classnames("header", {
      "nav_active--nav": !visible
    })}`}>

      {globalState.investorDisclaimerPopup.show && <InvestorDisclaimerPopup popupConfig={globalState.investorDisclaimerPopup} />}
      {globalState.investorApplyOnlinePopup.show && <InvestorApplyOnlinePopup popupConfig={globalState.investorApplyOnlinePopup} />}
      {globalState.investorEmailFriendPopup.show && <InvestorEmailFriendPopup popupConfig={globalState.investorEmailFriendPopup} />}
      {globalState.investorAuthFormPopup.show && <InvestorAuthFormPopup popupConfig={globalState.investorAuthFormPopup} />}
       {globalState.franceCaptchaPopup.show && <FranceCaptchaPopup popupConfig={globalState.franceCaptchaPopup} />}
      <div className={`${styles.hide_on_mobile} ${styles.nav_wrap} row`}>
      <div className={styles.logo_section} >
      <Link to="/Investor/home">
      <span className={`${styles.logo} sticky--logo`}></span>
      </Link>
     
      </div>
      <div className={`${styles.main_nav} main_nav_wrap`} >
          <div className={`${styles.top_section_nav} hide_on_sticky`}>
              <ul>
              <li><Link to="/"> European Site</Link></li>
              <li><a href={config.ukResidentsBaseUrl || '/'}>UK Site</a></li>
                <li><Link to="/Investor/contact-us">Contact us</Link></li>
                {/* <li className={`search_content ${searchIsActive ? "active":""}`}>
                <button className="search__btn" aria-label="submit search"><Icon size={25} icon={search} onClick={() => setSearchIsActive(!searchIsActive)}  /></button>
                <span className="search">
                 <input type="text" className="search__input" aria-label="search" placeholder="enter your search" onChange={searchOnchange} value={globalState.investorSearchTerm} onKeyPress={searchKeyPress} />
                  <button className="search__btn" aria-label="submit search" onClick={() => submitSearch(globalState.investorSearchTerm)}>Search</button>
                </span>

                </li> */}
              </ul>
          </div>
          <ul style={{float: 'right'}}>
          
          {menuItemEdges.sort(sortMenuItemByPosition).map(edge => {
                let slug = edge.node.slug
                let title = edge.node.title;
                console.log(edge.node, "edge.node.description")
            let description = edge.node.bodyText?.childrenMarkdownRemark[0].html;

           
                let allSubMenuTemplates = {
                  defaultTemplateSubMenus: edge?.node?.default_template,
                  cardsOnlyTemplateSubMenus: edge?.node?.cards_only_template,
                  tabsForFilesTemplateSubMenus: edge?.node?.tabs_for_files_template,
                  countryInformationTemplateSubMenus: edge?.node?.country_information_template,
                  newsListTemplateSubMenus: edge?.node?.news_list_template,
                  reportsTemplateSubMenus: edge?.node?.reports_template,
                  iframeTemplateSubMenus: edge?.node?.iframe_template,
                  currentVacanciesTemplateSubMenus: edge?.node?.current_vacancies_template,
                  advisersTemplateSubMenus: edge?.node?.advisers_template,
                  shareInformationTemplate: edge?.node?.share_information_template,
                  accordionTemplate: edge?.node?.accordion_template,
                  usaInvestorsOnlyInformationTemplate: edge?.node?.usa_investors_only_menu_template,
                  accordionForFilesTemplate: edge?.node?.accordion_for_files_template
                }

                return (
                  <li className={styles.primary_dropdown} key={slug}>
                    {title}
                  <div className={styles.dropdown_wrap}>
                      <div className={"container has_link_color"}>
                    <div className={"row"}>
                         <div className={"col-12 col-md-4"}>
                          <div className={styles.first_circle}></div>
                          <div className={styles.second_circle}></div>
                          <div className={styles.third_circle}></div>
                          <div className={styles.fourth_circle}></div>
                          <div className={styles.fifth_circle}></div>
                        
                          
                        </div>
                        <div className={"col-12 col-md-12"}>
                        <ul>

                        {compileSortedSubMenus(slug, allSubMenuTemplates, false)} 

                
                    </ul>
                        </div>
                        </div>
                    </div>
                  </div>
                    
                  </li>
                )
              })}

          </ul>
      </div>
    </div>
  
{/* START MOBILE HEADER */}
      
        <div className={`${styles.hide_on_desktop} ${styles.mobile_header} ${isActive ? `${styles.active}` : ""} row `} >      
        <div className={"col-6 col-md-3 col-lg-3" } style={{zIndex:'999999999', background:'#fff', padding:'15px'}}>
            <Link to="/Investor/home">
            <span className={styles.logo}></span>
            </Link>
      
        </div>

        <div className={`mobile-menu hide_on_desktop ${isActive ? "active" : ""}` } >
        <div className="mobile-menu_wrap">
          <ul>

            {menuItemEdges.sort(sortMenuItemByPosition).map((edge, id) => {
              let slug = edge.node.slug
              let title = edge.node.title;
           
              let allSubMenuTemplates = {
                defaultTemplateSubMenus: edge?.node?.default_template,
                cardsOnlyTemplateSubMenus: edge?.node?.cards_only_template,
                tabsForFilesTemplateSubMenus: edge?.node?.tabs_for_files_template,
                countryInformationTemplateSubMenus: edge?.node?.country_information_template,
                newsListTemplateSubMenus: edge?.node?.news_list_template,
                reportsTemplateSubMenus: edge?.node?.reports_template,
                iframeTemplateSubMenus: edge?.node?.iframe_template,
                currentVacanciesTemplateSubMenus: edge?.node?.current_vacancies_template,
                advisersTemplateSubMenus: edge?.node?.advisers_template,
                shareInformationTemplate: edge?.node?.share_information_template,
                accordionTemplate: edge?.node?.accordion_template,
                usaInvestorsOnlyInformationTemplate: edge?.node?.usa_investors_only_menu_template,
                accordionForFilesTemplate: edge?.node?.accordion_for_files_template

              }

              return (
            
                      <li onClick={() => setActiveMenuItem(id)} className={` ${(menuItemIsActive === id && !isNullUndefined(menuItemIsActive)) ? "":""}`}>{title}
                        <ul >
                          <li className="parent_li">

                          {compileSortedSubMenus(slug, allSubMenuTemplates, true)}        

                          </li>
                        </ul>
                      </li>                     
            
              )
      
             })}
            <hr/>
          


          </ul>
          <ul className="mobile_nav_footer">
              <li className={styles.landing_page_menu_item}><Link to="/"  onClick={() => setIsActive(false)}> European Site <span>|</span></Link></li>
              <li className={styles.landing_page_menu_item}><a href={config.ukResidentsBaseUrl || '/'} onClick={() => setIsActive(false)}>UK Site <span>|</span></a></li>
              <li className={styles.landing_page_menu_item}><Link to="/Investor/contact-us"  onClick={() => setIsActive(false)}>Contact us</Link></li>
            
            </ul>
        </div>
    </div>

        <div className={"col-8 col-md-9 col-lg-9"} style={{zIndex:'999999999', background:'#fff', padding:'20px'}}>
            
          <div className={styles.header_toggle}>
        
          
            <div className={styles.hamburger} onClick={() => toggleClass()} >
              <span className={styles.line}></span>
              <span className={styles.line}></span>
              <span className={styles.line}></span>
            </div>

          </div>

      </div>
     

  </div> 
  {/* END MOBILE HEADER */}
    </header>

{/* START MOBILE NAV */}

{/* END MOBILE NAV */}





  </div>
   );
}

export default Header;