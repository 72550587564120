import React from 'react';  
import { connect } from "react-redux"
import  styles from "./france-captcha-popup.module.css";
import {isNullUndefined} from "../../../util"
import {FranceCaptchaPopupSchema} from "../../../schemas/prompts/shared/france-captcha-popup"
import {hideFranceCaptchaPopup} from "../../../actions/shared/france-captcha-popup"
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { navigate } from 'gatsby'

class Popup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            captchaValidated: false
        }
    }

    closePopup = () => {
        let popupConfig = new FranceCaptchaPopupSchema()
        this.props.hideFranceCaptchaPopup(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    okHandler = async (okCallback) => {
        if(typeof okCallback === "function"){
            await okCallback()
        }
        this.closePopup()
    }

    captchaOnChange = (value, path) => {
        if(isNullUndefined(value)){
            return;
        }

        this.setState({captchaValidated: true}, () => {
            setTimeout(() => {
                this.closePopup();
                if(window && window.grecaptcha){
                    window.grecaptcha.reset()
                }
                navigate(path);
            }, 1500)
        })
    }
    
    captchaOnExpired = () => {
        toast.error("Recaptcha expired. Please reload to try again.");
        this.setState({captchaValidated: false})
    }

    captchaOnErrored = () => {
        toast.error("Something went wrong. Please try again.");
        this.setState({captchaValidated: false})
    }

    render() {  
        const { show, path } = this.props.popupConfig;

        if(!show) return <React.Fragment />

        return (  
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className={styles.popup}>  
                <div className={styles.popup_inner}>  
                
                <ReCAPTCHA
                    sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={(value) => this.captchaOnChange(value, path)}
                    onErrored={this.captchaOnErrored}
                    onExpired={this.captchaOnExpired}
                />

                </div>  
                </div>
            </React.Fragment> 
        );  
    }
}

const mapStateToProps = state => ({
    disclaimerPopup: state.investorDisclaimerPopup
})

const mapDispatchToProps = () => {
    return {
        hideFranceCaptchaPopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)