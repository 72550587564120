import React from 'react';  
import { connect } from "react-redux"
import  styles from "./apply-online.module.css";
import Editor from "./../../../Editor"
import {ApplyOnlinePopupSchema} from "../../../../schemas/prompts/careers/investor/apply-online"
import {hideInvestorApplyOnlinePopup} from "../../../../actions/investor/prompts/careers/apply-online"
import {constructInvestorApplicationFormEmailHtml, submitApplicationFormInvestor} from "../../../../controllers/investor/mailer"
import CircularProgress from "@material-ui/core/CircularProgress"
import { isEmailValid } from '../../../../util/index'

class Popup extends React.Component {
    constructor(props){
        super(props);
        this.reader = new FileReader();
        this.state = {
            name: "",
            email: "",
            phone: "",
            coverLetter: "",
            resume: "",
            loading: false,
            error: null,
            wasValidated: false
        }
    }

    closePopup = () => {
        let popupConfig = new ApplyOnlinePopupSchema()
        this.props.hideInvestorApplyOnlinePopup(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    onTextChange = ({target: {name, value}}) => {
        this.setState({[name]: value})
    }

    onTextChange = ({target: {name, value}}) => {
        this.setState({[name]: value})
    }

    handleFileLoaded = (e, name, file) => {
        let result = e.target.result;
        this.setState({[name]: {file: result, name: name, type: file.type, size: file.size, extension: file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name}})
    };

    onFileChange = (e) => {
        const fieldName = e.target.name;
        const file = e.target.files[0];
        this.reader.onloadend = (e) => this.handleFileLoaded(e, fieldName, file);
        this.reader.readAsDataURL(file)
    }

    clearForm = () => {
        let clearedFormData = Object.keys(this.state).reduce((acc, item) => {
            if(item === "loading" || item === "wasValidated"){
                acc[item] = false;
            }else if(item === "error"){
                acc[item] = null;
            }else{
                acc[item] = "";
            }
            return acc
        }, {});
        this.setState({...this.state, ...clearedFormData})
    }

    isEmpty = (items) => {
        if(items.includes("")){
            return true;
        }
        return false;
    }

    submitForm = async(okCallback) => {
        const {name, email, phone, coverLetter, resume, loading} = this.state;

        if(loading) return

        this.setState({wasValidated: true, loading: true, error: null})

        if(this.isEmpty([name, email, phone, coverLetter, resume])){
            this.setState({error: {response: {data: "Please fill in all required fields."}}, loading: false})
            return;
        }

        if(!isEmailValid(email)){
            this.setState({error: {response: {data: "Email is invalid."}}, loading: false})
            return;
        }

        let data = { 
            subject: "Investor and Media Relations - Career Application",
            html: constructInvestorApplicationFormEmailHtml({name, email, phone, coverLetter}),
            attachment: resume
        }

        try {
            await submitApplicationFormInvestor(data)
            this.clearForm()

            if(typeof okCallback === "function"){
                await okCallback()
            }
            this.closePopup()
        }catch(e){
            this.setState({error: e})
        }

        this.setState({loading: false, wasValidated: false})
    }

    render() {  
        const { show, title, jobPosition, jobType, country, location, description, okCallback, closeCallback, okLabel, closeLabel} = this.props.popupConfig;
        const {name, email, phone, resume, coverLetter, error, wasValidated} = this.state;

        if(!show) return <React.Fragment />

        return (  
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
           <strong>You are applying for the following position:</strong> 
            <p>
            <strong>{title}</strong> 
            </p> 
            <p>
            <strong>Job Title:</strong> {jobPosition}
            </p>
            <p>
            <strong>Job Type:</strong>  {jobType}
            </p>
            <p>
            <strong> Country:</strong> {country}
            </p>
            <p>
                <strong>Location:</strong> {location}
            </p>
            <div className="row">
                <div className="col-12">
                    <Editor type={"text"} name={"name"} label={"Name"} value={name} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && name.length < 1} />
                </div>
                <div className="col-12">
                  <Editor type={"text"} name={"email"} label={"Email"} value={email} required={true} onChange={this.onTextChange} variant={"outlined"}  error={wasValidated && !isEmailValid(email)}/>
                </div>
                <div className="col-12">
                   <Editor type={"text"} name={"phone"} label={"Telephone Number"} value={phone} required={true} onChange={this.onTextChange} variant={"outlined"}  error={wasValidated && phone.length < 1} />
                </div>
                <div className="col-12">
                  <Editor type={"text"} name={"coverLetter"} label={"Message / Cover Letter"} value={coverLetter} required={true} onChange={this.onTextChange} variant={"outlined"} multiline={true} rows={4} rowsMax={6} error={wasValidated && coverLetter.length < 1} />
                </div>
                <div className="col-12">
                    Resume (up to 2mb for file types .pdf, .doc, .docx)
                    <Editor type={"file"} name={"resume"} label={"Resume"}  required={true} onFileChange={this.onFileChange} variant={"outlined"} accept={".pdf,.doc,.docx"} error={wasValidated && resume === ""}/>
                </div> 
            </div>
            {error && (
                <div className="col-12">
                    <small className="text-danger">
                        {`${error.response ? error.response.data : "Something went wrong, please try again"}`}
                    </small>
                </div>
            )}
            <div className="text-center mt-3">
            <button className={"custom_btn dark bordered_btn"} onClick={async () => await this.submitForm(okCallback)}>
                {this.state.loading ? <CircularProgress size={20}/> : okLabel}
            </button>

            <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                {closeLabel}
            </button> 

            </div>
             
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = () => {
    return {
        hideInvestorApplyOnlinePopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)