import React from 'react'; 
import { Auth } from 'aws-amplify' 
import { connect } from "react-redux"
import  styles from "./auth-form.module.css";
import {FormPopupSchema} from "../../../schemas/prompts/forms/investor/form"
import {hideInvestorAuthFormPopup} from "../../../actions/investor/prompts/forms"
import Editor from "../../Editor"
import CircularProgress from "@material-ui/core/CircularProgress"
import {isEmailValid, isNullUndefined, validatePassword} from "../../../util"

class Popup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            login: {
                email: "",
                password: "",
                error: null
            },
            signup: {
                firstName: "",
                lastName: "",
                email: "",
                company: "",
                password: "",
                confirmPassword: "",
                error: null
            },
            forgotPassword: {
                email: "",
                show: false,
                error: null
            },
            wasValidated: false,
            loginPressed: false,
            registerPressed: false,
            forgotPasswordPressed: false,
            signupSuccess: false,
            resetPasswordSuccess: false,
            loginLoading: false,
            registerLoading: false
        }
    }

    closePopup = () => {
        this.resetForm()
        let popupConfig = new FormPopupSchema()
        this.props.hideInvestorAuthFormPopup(popupConfig.schema)
    }

    closeHandler = async (closeCallback) => {
        if(typeof closeCallback === "function"){
            await closeCallback()
        }
        this.closePopup()
    }

    onTextChange = ({target: {name, value}}, method) => {
        if(isNullUndefined(method)){
            return;
        }

        let newState = Object.assign({}, {...this.state[method], [name]: value, error: null})
        this.setState({[method]: newState})
    }

    resetForm = (callback) => {
        this.setState({
            login: {
                email: "",
                password: "",
                error: null
            },
            signup: {
                firstName: "",
                lastName: "",
                email: "",
                company: "",
                password: "",
                confirmPassword: "",
                error: null
            },
            forgotPassword: {
                email: "",
                show: false,
                error: null
            },
            wasValidated: false,
            loginPressed: false,
            registerPressed: false,
            forgotPasswordPressed: false,
            signupSuccess: false,
            resetPasswordSuccess: false,
            loginLoading: false,
            registerLoading: false
        }, async () => {
            if(typeof callback === "function"){
                await callback()
            }
        })
    }

    login = async (fileUrl, closeCallback) => {

        if(this.state.loginLoading) return;

        this.setState({wasValidated: true, loginPressed: true, login: {...this.state.login, error: null}}, async () => {

            let {password, email} = this.state.login;
    
            if(!isEmailValid(email)){
                this.setState({login: {...this.state.login, error: {message: "Your email seems invalid"}}})
                return;
            }

            if(password.length < 1){
                this.setState({login: {...this.state.login, error: {message: "Your password is too short"}}})
                return;
            }

            this.setState({loginLoading: true})
    
            try {
                const user = await Auth.signIn(email, password);
    
                if(!isNullUndefined(user)){
                    await this.closeHandler(closeCallback)
                    if(!isNullUndefined(fileUrl)){
                        window.open(fileUrl, "blank")
                    }
                }
                this.setState({loginLoading: false})
            } catch (error) {
                this.setState({login: {...this.state.login, error: error}, loginLoading: false})
                return;
            }

        })

    }

    signUp = async (e, path) => {

        if(this.state.registerLoading) return

        this.setState({wasValidated: true, registerPressed: true, signup: {...this.state.signup, error: null}}, async () => {
            for(let attribute of Object.keys(this.state.signup)){
                if(attribute !== "error" && (this.state.signup[attribute] === null || this.state.signup[attribute].length < 1)){
                    this.setState({signup: {...this.state.signup, error: {message: "Please fill in all required fields"}}})
                    return;
                }
            }
    
            let {password, confirmPassword, email} = this.state.signup;

            let validatedPassword = validatePassword(password);

            if(validatedPassword.error){
                this.setState({signup: {...this.state.signup, error: validatedPassword }});
                return;
            }
    
            if(password !== confirmPassword){
                this.setState({signup: {...this.state.signup, error: {message: "Your passwords do not match"}}})
                return;
            }
    
            if(!isEmailValid(email)){
                this.setState({signup: {...this.state.signup, error: {message: "Your email seems invalid"}}})
                return;
            }
    
            let {password: destructuredPassword, confirmPassword: destructuredConfirmPassword, error: signupError, ...attributes} = this.state.signup;
    
            this.setState({registerLoading: true})
    
            try {
                const {user} = await Auth.signUp({
                    username: email,
                    password: password,
                    attributes: {
                        email: email, 
                        "custom:firstName": attributes.firstName,
                        "custom:lastName": attributes.lastName,
                        "custom:company": attributes.company,
                        "custom:signupOriginUrl": `${window.location.pathname}`
                    },
                    clientMetadata: {
                        "redirectLink": isNullUndefined(path) ? "" : path
                    }
                });
                this.setState({signupSuccess: true, registerLoading: false})
            } catch (error) {
                this.setState({signup: {...this.state.signup, error: error}, registerLoading: false})
                return;
            }
        })

    }

    showForgotPasswordPage = () => {
        this.setState({forgotPassword: {...this.state.forgotPassword, show: true}})
    }

    requestPasswordReset = async (email, path) => {

        if(this.state.forgotPasswordPressed) return

        this.setState({wasValidated: true, forgotPasswordPressed: true, forgotPassword: {...this.state.forgotPassword, error: null}});

        if(!isEmailValid(email)){
            this.setState({forgotPasswordPressed: false, forgotPassword: {...this.state.forgotPassword, error: {message: "Your email is invalid"}}})
            return;
        }

        try {
            Auth.forgotPassword(email, {"redirectLink": isNullUndefined(path) ? "" : path})
                .then(data => {
                    this.setState({resetPasswordSuccess: true, forgotPasswordPressed: false})
                })
                .catch(error => {
                    this.setState({forgotPassword: {...this.state.forgotPassword, error: error}, forgotPasswordPressed: false})
                    return;
                });
        } catch(error) {
            this.setState({forgotPassword: {...this.state.forgotPassword, error: error}, forgotPasswordPressed: false})
            return;
        }
    }

    render() {  
        const { show, okCallback, path, fileUrl, closeCallback, okLabel, closeLabel} = this.props.popupConfig;
        const {login, signup, wasValidated, loginPressed, registerPressed, forgotPasswordPressed, signupSuccess, forgotPassword, resetPasswordSuccess, loginLoading, registerLoading} = this.state;

        if(!show) return <React.Fragment />


        if(signupSuccess){
            return (
                <div className={styles.popup}>  
                    <div className={styles.popup_inner}>  
                        <div className="row">
                            <div className="col-12">
                                <p>Your account was created successfully. An email with instructions on how to verify your account has been sent to {signup.email || " you"}.</p>
                                <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                                    Exit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(forgotPassword.show && !resetPasswordSuccess){
            return (
                <div className={styles.popup}>  
                    <div className={styles.popup_inner}>  
                        <div className="row">
                            <div className="col-12">
                                <h4>Forgot Your Password?</h4>
                                <p>Please enter the email adress that you used when you registered and an email containing instructions to reset your password will be sent to you</p>
                                
                                <Editor type={"text"} name={"email"} label={"Email Address"} value={forgotPassword.email} required={true} onChange={e => this.onTextChange(e, "forgotPassword")} variant={"outlined"} error={wasValidated && forgotPasswordPressed && !isEmailValid(forgotPassword.email)} />

                                {!isNullUndefined(forgotPassword.error) && <div className="col-12">
                                    <small className="text-danger">
                                        {forgotPassword.error.message}
                                    </small>
                                </div>}

                                <button className={"custom_btn dark bordered_btn"} onClick={async () => await this.requestPasswordReset(forgotPassword.email, path)}>
                                    {forgotPasswordPressed ? <CircularProgress size={20}/> : "Submit"}
                                </button>

                                <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                                    Exit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
        if(forgotPassword.show && resetPasswordSuccess){
            return (
                <div className={styles.popup}>  
                    <div className={styles.popup_inner}>  
                        <div className="row">
                            <div className="col-12">
                                <h4>Success</h4>
                                <p>An email containing instructions to reset your password has been sent to  {forgotPassword.email || " you"}.</p>

                                <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (  
            <div className={styles.popup}>  
            <div className={styles.popup_inner}>  
            
            

            <div className="row">
                <div className="col-12">Login</div>
                <div className="col-12">
                <Editor type={"text"} name={"email"} label={"Email Address"} value={login.email} required={true} onChange={e => this.onTextChange(e, "login")} variant={"outlined"} error={wasValidated && loginPressed && !isEmailValid(login.email)} />
                </div>
                <div className="col-12">
                  <Editor type={"text"} name={"password"} label={"Password"} value={login.password} required={true} onChange={e => this.onTextChange(e, "login")} variant={"outlined"} error={wasValidated && loginPressed && login.password.length < 1} />
                </div>
                {(login.error !== null) && <div className="col-12">
                    <small className="text-danger">
                        {login.error.message}
                    </small>
                </div>}
                <div className="col-12">
                    <button className={"custom_btn dark bordered_btn"} onClick={async () => await this.login(fileUrl, closeCallback)}>
                        {(loginLoading) ? "Logging In" : "Login"}
                    </button>
                </div>
                <div className="col-12">
                    <small style={{textDecoration: "underline", cursor: "pointer"}} onClick={this.showForgotPasswordPage}>
                        Forgot password?
                    </small>
                </div>

            </div>

            <div className="row">
                <div className="col-12">If you do not have an account please sign up using the following form</div>
                <div className="col-12">
                     <Editor type={"text"} name={"firstName"} label={"First Name"} value={signup.firstName} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && signup.firstName.length < 1} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"lastName"} label={"Last Name"} value={signup.lastName} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && signup.lastName.length < 1} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"email"} label={"Email Address"} value={signup.email} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && !isEmailValid(signup.email)} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"company"} label={"Company"} value={signup.company} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && signup.company.length < 1} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"password"} label={"Password"} value={signup.password} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && validatePassword(signup.password).error} />
                </div>
                <div className="col-12">
                     <Editor type={"text"} name={"confirmPassword"} label={"Confirm Password"} value={signup.confirmPassword} required={true} onChange={e => this.onTextChange(e, "signup")} variant={"outlined"} error={wasValidated && registerPressed && signup.confirmPassword.length < 1} />
                </div>
                {(signup.error !== null) && <div className="col-12">
                    <small className="text-danger">
                        {signup.error.message}
                    </small>
                </div>}
                <div className="col-12">
                    <button className={"custom_btn dark bordered_btn"} onClick={e => this.signUp(e, path)}>
                        {(registerLoading) ? "Registering" : "Register"}
                    </button>
                
                

                    <button  style={{marginLeft: '10px'}} className={"custom_btn dark bordered_btn"} onClick={async () => await this.closeHandler(closeCallback)}>
                        {closeLabel}
                    </button>
                
                </div>
            </div>


            
            
            </div>  
            </div>  
        );  
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = () => {
    return {
        hideInvestorAuthFormPopup
    }
}

export default connect(mapStateToProps, mapDispatchToProps())(Popup)